.cloud-img {
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 20;
  visibility: inherit;
  opacity: 1;
}

.text {
  text-align: center;
  font-size: 30px;
  margin-top: 40px;
  margin-bottom: 10px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.span-text {
  color: #076aab !important;
}

.p-text {
  text-align: center;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 30px;
}

.href-h5 {
  border-bottom: 2px solid #19a3b8;
}

.a-line {
  text-decoration-line: none;
}

.img-style {
  width: 80%;
  height: 200px;
  transition: transform 0.5s ease;
  margin-top: 23px;
  margin-bottom: 23px;
}
.img-style:hover {
  cursor: pointer;
  transform: scale(1.1); /* Enlarge the image to 110% of its original size */
}
.card {
  border: none !important;
}
.service-heading {
  font-family: lora;
  font-style: italic !important;
  font-weight: 400;
}
