.bg-custom {
  /* background-color: rgb(10, 49, 97); */
  background-color: #0F263A;
}

.navbar-text {
  color: #fff !important;
}

.head-nav {
  margin-right: 20px;
  height: 20px;
}
/* Hide the dropdown by default */
.dropdown-menu {
  display: none;
  color: red;
}

/* Show the dropdown when its parent 'li' is hovered */
.nav-item:hover > .dropdown-menu {
  display: block;
}

.navbar-text:hover,
.navbar-text:active {
  color: #fab90a !important;
}
.dropdown {
  position: relative;  /* Makes the ul's position relative so the dropdown positions relatively to it */
  float: right;  /* Ensures the dropdown is aligned to the right side of the navbar */
  display: inline-block;  /* Keeps the dropdown within the line flow, replace as needed */
}

.dropdown-menu {
  position: absolute;  /* Enables absolute positioning inside relative parent */
  top: 100%;  /* Positions the menu right below the ul */
  right: 0;  /* Aligns the menu to the right edge of the ul */
  display: none;  /* Initially hide the dropdown */
}

.special-margin {
  margin-right: 20px;
}
.dropdown:hover .dropdown-menu {
  display: block;  /* Show the dropdown when hovering over .dropdown */
}