/* Footer.css */
.footer1 {
    background-image: url("../assets/images/Footer_Image.jpg"); /* Path to your image */
    background-color: #003366; /* Adjust the color to match the background */
    background-size: cover; /* Cover the entire footer */
    background-position: center; 
    color: white;
    text-align: center;   
    bottom: 0; /* At the bottom */
    left: 0; /* Align to the left */
    width: 100%; /* Take full width */
    z-index: 10;     
    padding: 40px 0;
    
  }
  
  .footer-content p {
    margin: 0 0 10px; /* Adjust spacing as needed */
  }
  
  .footer-btn {
    background-color: #DA4A1C; /* Adjust the color to match the button */
    color: white;
    border: none;
    margin-top: 25px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 0px; /* Space before the bottom part */
  }
  
  .footer-btn:hover {
    background-color: darken(#DA4A1C, 10%); /* Darken the color on hover */
  }
  
  .footer-bottom {
    background-color: #06121C;
    color: #555759;
    font-size: 12px;
    border-top: 1px solid #c5c5c6; /* Add top border, adjust color as needed */
    padding-top: 10px;
    padding-left: 10px;    
    display: flex;
    justify-content: space-between;
    align-items: center;
  }  
  
  /* Responsive adjustments as needed */
  @media (max-width: 768px) {
    .footer-bottom {
      flex-direction: column;
    }
    .footer-navigation {
      margin-top: 20px;
    }
  }
  