body {
  background-color: #f8f9fa;
  font-family: Arial, sans-serif;
}

.container {
  margin-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  width: 80%;
}

.service-header {
  background-color: #007bff;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.iframedesign {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 20px;
  /* border: 1px solid #000; /* Adjust the color and width as needed */
  /* border-radius: 5px;  */
  display: flex;
  flex-direction: column;

  width: 100%;
}

.iframedesign:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.iframedesign iframe {
  border: none;
  width: 88%;
  height: 400px;
  align-self: center;
}

.location-text {
  font-size: 18px;
  margin-top: 10px;
  margin-left: 30px;
}

.address-text {
  margin-left: 30px;
  font-size: 15px;
  line-height: 1.5;
  margin-left: 30px;
}

.social-media {
  margin-top: 20px;
}

.social-media p {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin: 10px 0;
}

.social-media img {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}
