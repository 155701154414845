.containerside{
    /* display: flex;    */
    display: flex;
    height: 100vh; /* Viewport height */
    overflow: hidden;
  }
  .link {
    text-decoration: none;
    padding: 15px; /* Adjust padding as necessary */
    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    color: white; /* Text color */
    transition: background-color 0.3s; /* Smooth background transition for hover effect */
    border-top: 1px solid white; /* White line above each link */
  }
  .routes .link:first-child {
    border-top: none;
  }
.link.activeMenu {
  background-color: #DA4A1C;
}
  .icon {
    margin-bottom: 5px;
    margin-top: -5px;
    width: 34px;
    height: 32px;
    font-size: 30px;
  }
  
  .name {
    text-align: center;
    font-size: 14px; /* Center the text below the icon */
    margin-left: -5px;
  }
  
  .routes {   
    width: 130px; /* Width of the sidebar */
    color: white; /* Text color */
    position: fixed; /* Fixed sidebar */
    height: 100%; /* Full height */ 
    background-color: #004165; /* Dark blue background */
    overflow-y: auto; /* Scrollable content */
    z-index: 1000;
    top: 0;
    left: 0;
  }
  .routes:hover::-webkit-scrollbar {
    display: block; /* This will make the scrollbar appear on hover for Chrome and Safari */
  }
  .routes::-webkit-scrollbar {
    width: 0;
    background: transparent; /* Chrome/Safari/Webkit */
  }
  .routes::-webkit-scrollbar-track {
    background: #f0f0f0; /* Light grey track */
  }
  
  /* Style the scrollbar handle */
  .routes::-webkit-scrollbar-thumb {
    background-color: #c0c0c0; /* Light grey handle */
    border-radius: 10px;    
  }
 
  .link:hover {
    background-color: #DA4A1C; /* Slightly lighter blue on hover */
  }
  .link.active {
  background-color: #DA4A1C;
}

  .content-area {
    margin-left: 130px; /* Make space for the sidebar */
    width: calc(100% - 130px); /* Adjust width based on the sidebar width */
    overflow-y: auto; /* Enable scrolling for the content */
    height: 100vh; /* Full viewport height */
  }
  .header, .navbar {
    padding-left: 250px; /* Adjust this value to match the sidebar width */
  }
 
  /* Additional responsive adjustments can be added as needed */
  