/* SectorComponent.css */
/* .container {
    padding-top: 5rem;
    padding-bottom: 5rem;
} */

.sector-row {
    margin-bottom: 2rem; 
    transition: transform 0.15s ease;
}

/* Hover state for the entire row */
.sector-row:hover {
    /* Apply a slight lift and shadow */
    transform: translateY(-5px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.sector-row.flex-row-reverse .sector-image,
.sector-row .sector-image {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    transition: transform 0.15s ease; /* Smooth transition for image */
}

.sector-image:hover {
    /* Scale up the image slightly */
    transform: scale(1.10);
}

.sector-image img {
    max-width: 100%;
    height: auto;
    transition: box-shadow 0.15s ease; 
}

.sector-text {
    display: flex;  
    flex-direction: column;
    justify-content: flex-start;
}
.sector-text:hover{
    transform: scale(1.05);
}
strong{
    font-weight: bold;
    /* color: #132F5D; */
}
.sector-title {
    color: #132F5D;
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 0.5rem;
    margin-left: 7px;
}
.p-aicontent{
    margin-left: 5px;
    font-size: 14px;
}
.sector-paragraph {
    margin-top: 0;
}
