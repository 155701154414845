.partner-card {
  display: flex;
  width: 80%;  /* Control the width to your liking */
  margin-bottom: 20px;
  /* border: 1px solid #ccc; */
  padding: 15px;
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.1);  Optional: Adds shadow for better visual separation */
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.partner-image img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-right: 20px;
}

.partner-details {
  flex-grow: 1;
}

.title {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 5px;
}
.company-img {
  max-width: 100% !important;
  height: 100% !important;
}