#contact a {
  text-decoration: none;
}

#contact p {
  font-size: auto;
}

.IMG {
  display: flex;
  flex-direction: row;
  padding-top: 3%;
}

@media (max-width: 800px) {
  .IMG {
    flex-direction: column;
  }
}

.fontcolor {
  color: black;
}

.contact {
  padding-top: 7%;
}
.rightcont {
  padding-left: 3%;
  padding-top: 10px;
}
.middle {
  padding-top: 4%;
}
.head {
  height: 200px;
  background-color: #13314c;
  color: white;
  padding: 50px;
}
.gray {
  padding-top: 200px;
  height: 50px;
  background-color: rgb(132, 119, 119);
}
.mpc {
  padding-top: 7%;
}
/* .gm{
    width: 40%;

} */
.abt img {
  width: 100%;
  height: 40%;
  padding-top: 20%;
}
.fontcolor :hover {
  color: blue;
}
.title {
  font-size: 20px;
}
.abt1 img {
  height: 20%;
  width: 70%;
  padding-top: 10%;
}
.customImage img {
  /* border: 3px solid green; */
  display: flex;
  height: 100%;
  width: 100%;
  padding-top: 0;
}
.customRow {
  border: 1px solid #dfdfdf;
  margin: 0.625rem;
  border-radius: 0.313rem;
  transition: all 0.3s;
  padding: 2rem 2rem 1.25rem 1.25rem;
  margin-top: 30px;
}
.wrapper {
  font-family: Arial, Helvetica, sans-serif;
  
}

.customRow:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  /* background: pink; */
}
.imgg img {
  height: 600px !important ;
  width: 100% !important;
  padding-top: 10%;
}
.row {
  width: 100%;
}
.container {
  margin-top: 10px;
  font-family: Arial, Helvetica, sans-serif;
}
.customTitleContainer h3 {
  font-size: 1.875rem;
  line-height: 1.2;
  color: #444041;
}
.cTitle {
  font-size: 20px;
  font-family: 500;
}

.company-img {
  max-width: 100% !important;
  height: auto !important;
}
.client-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}

.client-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 30%;
  margin-bottom: 20px;
  border: 1px solid #dfdfdf;
  margin: 0.625rem;
  border-radius: 0.313rem;
  padding: 20px;
}
.client-item:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}

.client-logo {
  width: 150px; /* Adjust the size as needed */
  height: 150px; /* Maintain equal size for all logos */
  object-fit: contain; /* Ensure the logo fits within the given dimensions */
  margin-bottom: 10px;
}

.Myimg {
  height: 180px;
  width: 100%;
  margin-bottom: 4%;
}
.awsmanagedservice {
  height: 300px;
  margin-left: 50px;
  margin-bottom: 20px;
}
.middle h2{
  font-weight: bold;
    font-size: 2rem;

}

