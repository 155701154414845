/* ContactForm.css */
.contact-form-container {
    max-width: 900px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
  }
  
  .contact-form-header {
    margin-bottom: 1em;
    text-transform: uppercase;
    color: #333; /* Adjust the color to match your style */
  }
  
  .contact-form-card {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Soft shadow around the form */
    border-radius: 4px; /* Slight rounding of corners */
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-input,
  .form-select {
    padding: 10px;
    /* margin-bottom: 10px; */
    margin-bottom: 20px; /* Spacing between fields */
    border: 1px solid #ccc; /* Light grey border */
    border-radius: 4px; /* Slight rounding of corners */
  }
  
  .form-select option {
    padding: 10px; /* Padding inside the select dropdown */
  }
  
  .submit-button {
    background-color: #ffcc00; /* Bright yellow for the submit button */
    color: #fff; /* White text */
    padding: 10px 20px;
    border: none;
    width: 30%;
    border-radius: 4px; /* Slightly rounded borders for the button */
    cursor: pointer; /* Hand cursor on hover */
    font-weight: bold; /* Bold font */
    text-transform: uppercase; /* Uppercase text */
    transition: background-color 0.2s; /* Transition for a smooth color change on hover */
    border-radius: 20px; /* More pronounced rounded corners for the button */
  }
  
  .submit-button:hover {
    background-color: #65D0F2; /* Slightly darker yellow on hover */
  }
  /* ContactForm.css */
.error-message {
    color: red; /* Or any color you prefer */
    margin-top: 10px; /* Spacing above the error message */
    border: 1px solid red; /* Border color */
    background-color: #CDF3A5; /* Light red background */
    padding: 4px;
    border-radius: 4px; /* Rounded corners for the error message box */
  }
  .success-message {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
}
  
  /* Responsive adjustments as needed */
  @media (max-width: 768px) {
    .contact-form-container {
      margin: 20px;
    }
  }
  