.container {
  display: flex;
}

.routes {
  margin-right: 20px; /* Adjust margin as needed */
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  padding: 10px;
}

.link:hover {
  background-color: #f0f0f0;
}

.active {
  font-weight: bold;
  color: blue; /* Adjust active link color as needed */
}

.icon {
  margin-right: 5px; /* Adjust margin as needed */
}

.name {
  margin-left: 5px; /* Adjust margin as needed */
}





.Myimg{

  height:100%;
  padding-right: 0%;
  width:100% ;
  
  padding-top: 10%;
  margin-top: 0;
  
  
}
.one{

}
.row3 img{

  height:100%;
  
  width:100% ;
  
 }
 .row4 img{

  height:100%;
  
  width:100% ;
 
  
 }
/* .Myimg2{

  height:100%;
  padding-right: 0%;
  width:100% ;
  padding-bottom: 10%;
 padding-top: 10px;
  
  

} */


 
.row a{
  text-decoration: none;
}
.contactrow {
  padding-top: 100px;
  border: #b32d2d;
 

}
.row3 p{
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;


}
.contactrow{
  border: thin;
}
.row4 p{
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  margin-left: 10px;

}
.row2 p{
  padding-top: 10px;

}
@media only screen and (max-width: 800px) {
  .Myimg1{
flex-direction: column;  }
}

.row3{
  display: flex;
  flex-direction: row;
  text-align: justify;
  font-size: 14px;
}
.row4{
  display: flex;
  flex-direction: row;
  text-align: justify;
  font-size: 14px;
  
}




