.header {
  background-color: #13314C !important;   
  color: #ffffff;
  padding: 20px;
  text-align: center;
  height: 200px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.header h1 {
  margin: 0;
  font-size: 2em; 
}
.header-line {
  height: 30px;
  background-color: #F2F4F6; 
  width: 100%; 
}