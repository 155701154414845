.cu{
   
    text-align: justify;
    width: 80%;
    background-image: url("../assets/images/contactus.png");
    border: 3px solid #E9E9E9;
    background-repeat: no-repeat;
    background-position: bottom 6px right 8px;
    padding: 10%;
    margin-left: 80px;

    
}
